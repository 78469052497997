.position-relative {
    position: relative;
}

.is-invalid {
    background-image: none !important;
}

.invalid-feedback {
    position: absolute;
    bottom: -20px;
    width: 100%;

}

.packages-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Adjust this value to control spacing between items */
    justify-content: flex-start; /* Adjust as needed for horizontal alignment */
    align-items: flex-start; /* Adjust as needed for vertical alignment */
}

/* Add additional styling rules as needed */


/* ButtonsContainer.css */

.buttons-container {
    display: inline-flex;
    border-radius: 20px;
    margin-bottom: 1rem;
    overflow: hidden;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .custom-button {
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: none;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .custom-button:first-child {
    border-radius: 20px 0 0 20px;
  }
  
  .custom-button:last-child {
    border-radius: 0 20px 20px 0;
  }
  
  .custom-button:hover {
    background-color: #e0e0e0;
  }

  .custom-button.active {
    background-color: #8DFF36;
    
  }
  